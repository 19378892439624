@import url('https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;700;800&display=swap');
@import './assets/styles/Global.scss';

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html{
  scroll-behavior: smooth;
}
body{
  font-family: 'Commissioner', sans-serif;
}
ul{
  list-style: none;
}
a{
  text-decoration: none;
  color: #000;
}
.App{
  max-width: 1920px;
  margin: auto;
}