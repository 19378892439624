@import '../assets/styles/Global.scss';

.link_wrap{
    a{
        color: $white;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px; /* 200% */
    }
    a:hover{
        font-weight: 700;
    }
}
.link_wrap:last-child{
    height: 64px;
    width: 199px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 17px;
    background: $bgBlack;
    transition: background 0.5s ease;
    a{
        font-weight: 800;
    }
}
.link_wrap:last-child:hover{
    background: $hoverbtn;
}
@media screen and (max-width: 768px) {
    .link_wrap{
        a{
            font-size: 18px;
        }
    }
    .link_wrap:last-child{
        margin: 0;
        background: $red;
    }
}